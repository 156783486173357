<template>
    <div class="section-content pt-4" :class="{ 'mt-10': $vuetify.breakpoint.xs, 'mt-n3': $vuetify.breakpoint.sm }">
        <hb-header full :divider=false>
            <hb-page-header title="Tenants" :description="showTabs ? 'Manage tenants and national accounts.' : ''">
            </hb-page-header>
        </hb-header>
        <hb-header fewer-actions :padding="false" align-top class="mt-n2" :divider=showTabs>
            <template v-slot:left>
                <hb-tabs v-model="activeTab" v-if="showTabs">
                    <v-tab v-for="menuOption in computedMenuOptions" :key="menuOption.key" :to="menuOption.route"
                        @click="view = menuOption.key" :ripple="false">{{ menuOption.label }}</v-tab>
                </hb-tabs>
            </template>
        </hb-header>
        <!-- <router-view></router-view> -->
        <div style="flex: 1">
            <hb-report :key="report_key" report_type="active_tenants" v-if="view == 'Tenants'"
                :actions_panel="['advanced', 'filters', 'export', 'bulk_edit', 'columns', 'save']" show_views
                right_click row_click show_search :column-conf="columnConf" @rowClicked="viewLease"
                :show_default_report_option="true"></hb-report>
            <national-account-report ref="NationalAccountReport"
                v-else-if="view == 'NationalAccounts'"></national-account-report>

            <national-account v-if="showTabs" @refresh="callReport"></national-account>
        </div>
    </div>
</template>

<script type="text/babel">
import HbReport from '../assets/HummingbirdReportViewer.vue';
import StatusCellRenderer from '@/components/BI/StatusCellRenderer.vue'
import NationalAccount from './NationalAccount.vue';
import NationalAccountReport from './NationalAccountReport.vue';
import { mapGetters, mapActions } from "vuex";
import { EventBus } from '../../EventBus';

export default {
    name: "Tenants",
    data() {
        return {
            activeTab: '',
            showTabs: true,
            report_key: 0,
            report_key_na: 1,
            view: this.$route.name,
        }
    },
    components: {
        HbReport,
        StatusCellRenderer,
        NationalAccount,
        NationalAccountReport
    },
    created() {
        EventBus.$on('refetch_data', () => this.fetchData());
    },
    destroyed() {
        EventBus.$off('refetch_data', () => this.fetchData());
    },
    filters: {

    },
    mounted() {
        this.view = this.$route.name
    },
    computed: {
        ...mapGetters({
            hasPermission: "authenticationStore/rolePermission",
        }),
        columnConf() {
            return {
                lease_rent_plan_status: {
                    cellRenderer: 'StatusCellRenderer',
                    cellRendererParams: {
                        leaseStatus: true,
                        general: true
                    },
                },
            }
        },
        computedMenuOptions() {
            const hasNationalAccountsPermission = this.hasPermission("national_account_enabled");
            if (!hasNationalAccountsPermission) {
                this.showTabs = false; //remove tab
                this.view == 'Tenants'; // makes defalut view tenant
            }
            else {
                this.showTabs = true;
                return [
                    {
                        key: "Tenants",
                        label: "Tenants",
                        route: '/tenants'
                    },
                    {
                        key: "NationalAccounts",
                        label: "National Accounts",
                        route: '/national-accounts',
                    },
                ]
            }
        }
    },
    methods: {
        fetchData() {
            this.report_key++;
        },
        viewLease(data) {
            let path = `/contacts/${data.tenant_id}`;
            if (data.unit_id) path += `?unit_id=${data.unit_id}`
            if (data.tenant_id) this.$router.push(path);
        },
        callReport() {
            this.$refs.NationalAccountReport.fetchNationalData()
        }
    },

    watch: {
    }

}
</script>
<style scoped></style>
